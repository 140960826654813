<template>
    <titleBar />
</template>
<script>
import  titleBar  from './components/titleBar'
export default{
    components: {
        titleBar
  },
}

</script>
