import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layouts'
Vue.use(Router)

const routes = [
  {
    path: '/',
    // component:()=>import("../views/big-screen"),
    component:()=>import("../views/index"),
    
  }
]

const router = new Router({
  routes: routes
})

export default router