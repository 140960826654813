<template>
    <div>
        <div class="nav-wrapper">
            <div class="title">
                <h1 style="color:#ffffff">这个是个标题</h1>
                </div>
            <dv-decoration-5 />
        </div>
        <section class="app-main">
        <transition name="fade-transform" mode="out-in">
            <router-view :key="key" />
        </transition>
    </section>
    </div>
</template>
<script>
export default {
    name:'titleBar',
    methods:{
        toMain(){
            //this.$route.push('/main');
            //console.log( this.$route.path)
        }
    },
    computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>
<style scoped>
.nav-wrapper {
  background-image: url('/images/bg.png');
  background-repeat: repeat;
  background-size: 100% 100%;
  box-shadow: 0 0 3px blue;
  display: flex;
  flex-direction: column;
}
.app-main {
  /*50 = navbar  */
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
}
</style>
